import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/base.scss";

//Components
import Header from "./components/Header";
import Footer from "./components/Footer";

//Pages
import HomePage from "./pages/HomePage";
import SkillsPage from "./pages/SkillsPage";
import ProjectsPage from "./pages/ProjectsPage";
import PageNotFound from "./pages/PageNotFound";
import AdminPage from "./pages/AdminPage/AdminPage";

//Providers
import AuthContextProvider from "./contexts/AuthContext";
import SkillsContextProvider from "./contexts/SkillsContext";
import HomeContextProvider from "./contexts/HomeContext";
import ProjectsContextProvider from "./contexts/ProjectsContext";

const App = () => {
  // useEffect(() => {
  //   window.location.protocol !== 'https:' && window.location.hostname === 'casal.ar'
  //   ? window.location.assign("https://casal.ar")
  //   : window.location.hostname === 'www.casal.ar' && window.location.assign("https://casal.ar")
  // }, [])

  return (
    <Router>
      <AuthContextProvider>
        <Header />
        <Switch>
          <Route exact path="/">
            <HomeContextProvider>
              <HomePage />
            </HomeContextProvider>
          </Route>
          <Route exact path="/projects">
            <ProjectsContextProvider>
              <ProjectsPage />
            </ProjectsContextProvider>
          </Route>
          <Route exact path="/skills">
            <SkillsContextProvider>
              <SkillsPage />
            </SkillsContextProvider>
          </Route>
          <Route exact path="/admin" component={AdminPage} />
          <Route component={PageNotFound}></Route>
        </Switch>
      </AuthContextProvider>
      <Footer />
    </Router>
  );
};

export default App;
